import React from 'react';

const Section_3 = () => {
    return (
        <section
            className="container-wide d-flex f-align-center f-justify-center animate"
            id="UTILITY"
            data-id="about"
            data-index="2"
        >
            <div className="container d-flex f-align-center m-f-column gap-40px">
                <div className="w-50 d-flex f-dir-col">
                    <h2
                        className="p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromLeft"
                    >
                        THE UTILITY OF AKASHA
                    </h2>
                    <p
                        className="p-relative animate-elem animate-fadeIn animate-delay-04 animate-slideFromLeft"
                    >
                        In the realm of decentralized ecosystems, a cryptocurrency coin serves as the essential fuel that powers various transactions and interactions within the network. Acting as a medium of exchange, this digital currency facilitates seamless transfers of value between users, incentivizing participation and collaboration. By utilizing smart contracts, it can automate processes and ensure transparency while rewarding contributors for their efforts, thus fostering community engagement. As the backbone of the ecosystem, the cryptocurrency coin not only enhances operational efficiency but also creates economic opportunities for all participants, driving innovation and growth in an interconnected digital landscape.
                    </p>
                </div>
                <div
                    className="w-50 p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromRight"
                >
                    <video autoPlay loop muted playsInline style={{borderRadius:'30px'}}>
                        <source src="/video/Utility.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
};

export default Section_3;
