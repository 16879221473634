import React from 'react';
import logoImg from '../../assets/images/logo.svg'
import twitterImg from '../../assets/images/twitter.svg'
import telegramImg from '../../assets/images/telegram.svg'
import gitbookImg from '../../assets/images/gitbook.svg'
import discordImg from '../../assets/images/discord.svg'

const Footer = () => {
    return (
        <footer
            className="container animate-elem animate-fadeIn animate-delay-05 animate-scale-from-small"
        >
            <div className="d-flex f-dir-col f-align-start f-align-center">
                <img src={logoImg} alt="Akasha logo" className="mb-20" />
                2024 Akasha, All Rights Reserved.
            </div>
            <div className='EMPTY'></div>
            <div className='EMPTY'></div>
            <div className='EMPTY'></div>
            <div className='EMPTY'></div>
            
            <div>
                <h5 className='text-center'>Social Media</h5>
                <ul className="social f-justify-center">
                    <li>
                        <a href="/">
                            <img src={twitterImg} alt="Twitter" />
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <img src={telegramImg} alt="Telegram" />
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <img src={discordImg} alt="Discord" />
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <img src={gitbookImg} alt="Gitbook" />
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
