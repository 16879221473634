import React from 'react';
import Header from './common/Header';

import akashaImg from '../assets/images/logo.svg'
const Section_1 = () => {
    return (
        <section
            className="container-wide d-flex f-align-center f-justify-center animate"
            id="HOME"
            data-id="about"
            data-index="2"
        >
            <Header />
            <div className="container d-flex f-align-center m-f-column gap-40px"
                style={{marginTop:'100px'}}
            >     
                <div className="w-50 d-flex f-dir-col">
                    <img 
                        src={akashaImg} alt='Akasha' 
                        className="p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromLeft"
                    />
                    <p
                        className="p-relative animate-elem animate-fadeIn animate-delay-04 animate-slideFromLeft"
                    >
                        The ether that permeates the universe. It is considered to be the first element created during the cosmic evolution and is believed to contain the knowledge of the universe. Akasha is the source of all energy and the essence of all things.
                    </p>
                </div>
                <div
                    className="w-50 p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromRight"
                    style={{display: 'flex', justifyContent:'center'}}
                >
                    <video autoPlay loop muted playsInline style={{borderRadius: '30px', width: "90%"}}>
                        <source src="/video/Akasha-Man-2.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
};

export default Section_1;
