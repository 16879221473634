import React from 'react';

const Section_5 = () => {
    return (
        <section
            className="container-wide d-flex f-align-center f-justify-center animate"
            id="ROADMAP"
            data-id="about"
            data-index="2"
        >
            <div className="container d-flex f-align-center m-f-column gap-40px">
                <div className="w-50 d-flex f-dir-col f-align-center">
                    <h2
                        className="p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromLeft"
                    >
                        ROADMAP
                    </h2>
                    <ul className="roadmap-items p-relative animate-elem animate-fadeIn animate-delay-04 animate-slideFromLeft">
                        <li>
                            <p>SEPTEMBER 15, 2024 PUBLIC COIN LAUNCH</p>
                        </li>
                    </ul>
                </div>
                <div
                    className="w-50 p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromRight"
                >
                    <video autoPlay loop muted playsInline style={{borderRadius:'30px'}}>
                        <source src="/assets/video/roadmap.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
};

export default Section_5;
