import React, { useEffect } from 'react';
import Hero from '../components/Hero';
import RWA from '../components/RWA';
import Strengths from '../components/Strengths';
import Features from '../components/Features';
import DePIN from '../components/DePIN';
import Tech from '../components/Tech';
import Airdrop from '../components/Airdrop';
import Roadmap from '../components/Roadmap';
import Contributors from '../components/Contributors';
import Partners from '../components/Partners';
import Saying from '../components/Saying';
import Section_1 from '../components/Section-1';
import Section_2 from '../components/Section-2';
import Section_3 from '../components/Section-3';
import Section_4 from '../components/Section-4';
import Section_5 from '../components/Section-5';
import Section_6 from '../components/Section-6';

const Home = () => {
    useEffect(() => {
        // Function to activate following sections after pinning
        function activateFollowingSections() {
            // Select all sections after the pinned section (adjust selector as needed)
            const sections = document.querySelectorAll("section");

            // Loop through each section and create a ScrollTrigger
            sections.forEach((section) => {
                ScrollTrigger.create({
                    trigger: section,
                    start: "top 75%", // When the top of the section hits 75% of the viewport height
                    end: "bottom 25%", // When the bottom of the section hits 25% of the viewport height
                    onEnter: () => section.classList.add("active"),
                    onLeave: () => section.classList.remove("active"), // Optional: remove class when leaving
                    onEnterBack: () => section.classList.add("active"), // Re-add class when scrolling back
                    onLeaveBack: () => section.classList.remove("active"), // Optional: remove class when leaving back
                });
            });
        }

        // Initialize the following sections' scroll triggers
        activateFollowingSections();

    }, []);

    return (
        <div id="smooth-wrapper">
            <div
                className="w-full fixed"
                style={{height: '100vh'}}
            >
                <video className='bg-video' autoPlay loop muted playsInline>
                    <source src="/video/background.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="main" id="smooth-content">
                <Section_1 />
                <Section_2 />
                <Section_3 />
                <Section_4 />
                <Section_5 />
                <Section_6 />
            </div>
        </div>
    );
};

export default Home;
