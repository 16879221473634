import React, { useEffect, useState } from 'react';
import Loading from './pages/Loading';
import Home from './pages/Home';

const App = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const minLoadingTime = 2000; // milliseconds
        const readyTime = new Date();
        
        const checkLoading = () => {
            const now = new Date();
            if (now - readyTime > minLoadingTime) {
                setLoaded(true);
            } else {
                setTimeout(checkLoading, 100);
            }
        };

        checkLoading();
    }, []);

    return (
        <div>
            {loaded ? <Home /> : <Loading />}
        </div>
    );
};

export default App;
