import React from 'react';
import Footer from './common/Footer';
import akashaImg from '../assets/images/logo.svg'

const Section_6 = () => {
    return (
        <section
            className="elven container-wide d-flex f-align-center f-justify-center p-relative animate"
            id="PARTNERS"
            data-id="partners"
            data-index="10"
        >
            <div className="container d-flex f-align-center f-dir-col">
                <div className="w-50 d-flex f-dir-col text-center">
                    <img src={akashaImg} alt='Akasha' />
                    <h2
                        className="p-relative animate-elem animate-fadeIn animate-delay-01 animate-slideFromBottom animate-scale-from-small"
                    >
                        PARTNERS
                    </h2>
                    <p
                        className="p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromBottom animate-scale-from-small"
                    >
                        Our esteemed network of partners, each a testament to their
                        trust in Akasha's vision for innovation and success in DeFi.
                    </p>
                </div>
                <div
                    className="partners d-grid p-relative animate-elem animate-fadeIn animate-delay-05 animate-slideFromBottom animate-scale-from-small f-justify-center"
                    style={{justifyContent: 'center'}}
                >
                    <a href="#" aria-label={`Partner 12`}>
                        <img src={`/assets/images/partner-12.svg`} alt={`Partner 12`} />
                    </a>
                    <div className='EMPTY'></div>
                    <a href="#" aria-label={`Partner 18`}>
                        <img src={`/assets/images/partner-sol.svg`} alt={`Partner 18`} />
                    </a>
                    <div className='EMPTY'></div>
                    <a href="#" aria-label={`Partner 2`}>
                        <img src={`/assets/images/partner-2.svg`} alt={`Partner 2`} />
                    </a>
                </div>
            </div>
            
            <Footer />
        </section>
    );
};

export default Section_6;
