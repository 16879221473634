import React, { useState } from 'react';

import logoImg from '../../assets/images/logo.svg'
import twitterImg from '../../assets/images/twitter.svg'
import telegramImg from '../../assets/images/telegram.svg'
import gitbookImg from '../../assets/images/gitbook.svg'
import discordImg from '../../assets/images/discord.svg'

const Header = () => {    
    const [navOpen, setNavOpen] = useState(false);
    const handleNavClick = () => {
        setNavOpen(!navOpen);
    };

    return (
        <header className={`clearfix ${navOpen ? "open" : ""}`}>
            <div className="container d-flex f-align-center f-justify-between">
                <div className="w-30 logo-container">
                    <a href="/" className="logo">
                        <img src={logoImg} alt="Akasha" title="Akasha" />
                    </a>
                </div>
                <div className="d-flex f-align-center f-justify-center w-40">
                    <ul className={`nav ${navOpen ? "open" : ""}`}>
                        <li>
                            <a href="#HOME" className="active mobile-nav-item scroll-to" onClick={handleNavClick}>HOME</a>
                        </li>
                        <li>
                            <a href="#PARTNERS" className="mobile-nav-item scroll-to" onClick={handleNavClick}>PARTNERS</a>
                        </li>
                        <li>
                            <a href="#TOKENOMICS" className="mobile-nav-item scroll-to" onClick={handleNavClick}>TOKENOMICS</a>
                        </li>
                        <li>
                            <a href="#FEATURES" className="mobile-nav-item scroll-to" onClick={handleNavClick}>FEATURES</a>
                        </li>
                        <li>
                            <a href="#ROADMAP" className="mobile-nav-item scroll-to" onClick={handleNavClick}>ROADMAP</a>
                        </li>
                        {/* <li>
                            <a href="/" className="mobile-nav-item">Blog</a>
                        </li> */}
                        <li>
                            <a href="" aria-label="Twitter" onClick={handleNavClick}>
                                <img src={twitterImg} alt="Twitter" />
                            </a>
                        </li>
                        <li>
                            <a href="" aria-label="Telegram" onClick={handleNavClick}>
                                <img src={telegramImg} alt="Telegram" />
                            </a>
                        </li>
                        <li>
                            <a href="" aria-label="Gitbook" onClick={handleNavClick}>
                                <img src={gitbookImg} alt="Gitbook" />
                            </a>
                        </li>
                        <li>
                            <a href="" aria-label="Discord" onClick={handleNavClick}>
                                <img src={discordImg} alt="Discord" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="nav-icon" onClick={handleNavClick}>
                <span></span>
                <span></span>
            </div>
        </header>
    );
};

export default Header;
