import React from 'react';

const Section_2 = () => {
    return (
        <section
            className="container-wide d-flex f-align-center f-justify-center animate"
            id="FEATURES"
            data-id="about"
            data-index="2"
        >
            <div className="container d-flex f-align-center m-f-column gap-40px">
                <div
                    className="w-50 p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromLeft"
                >
                    <video autoPlay loop muted playsInline style={{borderRadius: '30px'}}>
                        <source src="/video/uNIVERSE.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="w-50 d-flex f-dir-col">
                    <h2
                        className="p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromRight"
                    >
                        INTERCONNECTED NATURE OF THE UNIVERSE
                    </h2>
                    <p
                        className="p-relative animate-elem animate-fadeIn animate-delay-04 animate-slideFromRight"
                    >
                        The AKASHA (ether) that permeates the universe. It is considered to be the first element created during the cosmic evolution and is believed to contain the knowledge of the universe.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Section_2;
