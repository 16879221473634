import React from 'react';

const Loading = () => {
    return (
        <div className="loading active" aria-busy="true">
            <div className="loading-container mix-blend-lighten">
                <video autoPlay loop muted playsInline>
                    <source src="/assets/video/loader.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    );
};

export default Loading;
