import React from 'react';

import tokenoIcon from '../assets/images/tokenomics-icon.svg'
import akashaImg from '../assets/images/logo.svg'
const Section_4 = () => {
    return (
        <section
            className="eight container-wide d-flex f-align-center f-justify-center animate"
            id="TOKENOMICS"
            data-id="roadmap"
            data-index="8"
        >
            <div
                className="container d-flex f-align-center m-f-column roadmap-container"
            >
                <div
                    className="Akasha-tokeno-wrap w-50 p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromLeft animate-scale-from-small"
                >
                    <video autoPlay loop muted playsInline className='Akasha-tokeno'>
                        <source src="/assets/video/tokenomics.mp4" type="video/mp4" />
                    </video>
                </div>
                <div
                    className="w-50 d-flex f-dir-col animate-scale-from-small"
                >
                    <div className='d-flex f-dir-col f-justify-center f-align-center'>
                        <h2
                            className="p-relative animate-elem animate-fadeIn animate-delay-03 animate-slideFromRight"
                        >
                            TOKENOMICS
                        </h2>
                        <div
                            className="flex flex-col w-full p-relative animate-elem animate-fadeIn animate-delay-04 animate-slideFromRight"
                        >
                            <div className="d-flex f-dir-row f-align-center">
                                <img src={tokenoIcon} className="w-4 gap-x-4 flex relative z-10 overflow-hidden" alt="AKASHA" />
                                <p className="ml-10 mb-0">TOTAL SUPPLY: TBA</p>
                            </div>
                            <div className="d-flex f-dir-row f-align-center items-center gap-x-2">
                                <img src={tokenoIcon} className="w-4 flex relative z-10 overflow-hidden" alt="AKASHA" />
                                <p className="ml-10 mb-0">CIRCULATING SUPPLY: TBA</p>
                            </div>
                            <div className="d-flex f-dir-row f-align-center items-center gap-x-2">
                                <img src={tokenoIcon} className="w-4 flex relative z-10 overflow-hidden" alt="AKASHA" />
                                <p className="ml-10 mb-0">LOCKED: TBA</p>
                            </div>
                            <div className="d-flex f-dir-row f-align-center items-center gap-x-2">
                                <img src={tokenoIcon} className="w-4 flex relative z-10 overflow-hidden" alt="AKASHA" />
                                <p className="ml-10 mb-0">MARKETING: TBA</p>
                            </div>
                            <div className="d-flex f-dir-row f-align-center items-center gap-x-2">
                                <img src={tokenoIcon} className="w-4 flex relative z-10 overflow-hidden" alt="AKASHA" />
                                <p className="ml-10 mb-0">CONTRACT ADDRESS: TBA</p>
                            </div>
                        </div>
                        <img 
                            src={akashaImg} alt='Akasha' 
                            className="p-relative animate-elem animate-fadeIn animate-delay-05 animate-slideFromRight" 
                            style={{width: '100%'}}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section_4;
